import { Helmet } from "react-helmet";
import Contact from "@/components/Contact";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import ProfileCard from "@/components/gentle-treatment/ProfileCard";
import BenefitsSection from "@/components/gentle-treatment/BenefitsSection";
import TreatmentApproaches from "@/components/gentle-treatment/TreatmentApproaches";
import TreatmentTimeline from "@/components/gentle-treatment/TreatmentTimeline";

const GentleTreatment = () => {
  const pageTitle = "Gentle Physiotherapy Treatment Adelaide | Caring Approach";
  const pageDescription = "Specialized gentle physiotherapy treatment in Adelaide. Safe and effective care for seniors, post-surgery recovery, and chronic conditions. Evidence-based gentle approach.";
  const canonicalUrl = "https://physioadelaide.com/gentle-treatment";
  const ogImage = "https://physioadelaide.com/og-image.png";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:site_name" content="Physio Adelaide" />
        <meta property="og:locale" content="en_AU" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={ogImage} />
        <meta name="geo.region" content="AU-SA" />
        <meta name="geo.placename" content="Adelaide" />
        <meta name="geo.position" content="-34.9285;138.6007" />
        <meta name="ICBM" content="-34.9285, 138.6007" />
      </Helmet>

      <main className="min-h-screen">
        <div className="container mx-auto px-4 py-4">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink href="/">Home</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>Gentle Treatment</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>

        <section className="py-20 bg-secondary">
          <div className="container mx-auto px-4">
            <h1 className="text-4xl md:text-6xl font-bold text-accent mb-8">
              Gentle Physiotherapy Treatment
            </h1>
            <p className="text-lg md:text-xl text-muted-foreground mb-8">
              Expert care with a gentle touch, perfect for sensitive conditions and recovery.
            </p>
          </div>
        </section>

        <section className="py-20">
          <div className="container mx-auto px-4">
            <div className="max-w-4xl mx-auto space-y-12">
              <ProfileCard />
              <BenefitsSection />
              <TreatmentApproaches />
              <TreatmentTimeline />
            </div>
          </div>
        </section>

        <Contact />
      </main>
    </>
  );
};

export default GentleTreatment;