const BenefitsSection = () => {
  return (
    <div>
      <h2 className="text-2xl font-bold text-accent mb-6">Key Benefits of Gentle Physiotherapy</h2>
      <ul className="space-y-4">
        <li className="flex items-start">
          <span className="mr-2">•</span>
          <span>Reduced pain and discomfort during treatment</span>
        </li>
        <li className="flex items-start">
          <span className="mr-2">•</span>
          <span>Suitable for seniors and post-surgery recovery</span>
        </li>
        <li className="flex items-start">
          <span className="mr-2">•</span>
          <span>Progressive improvement without aggravating conditions</span>
        </li>
      </ul>
    </div>
  );
};

export default BenefitsSection;