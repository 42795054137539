import { motion } from "framer-motion";

interface PageHeaderProps {
  topic: string;
  introduction: string;
}

const PageHeader = ({ topic, introduction }: PageHeaderProps) => (
  <section className="relative py-20 bg-secondary">
    <div className="container mx-auto px-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="max-w-4xl mx-auto text-center"
      >
        <h1 className="text-4xl md:text-6xl font-bold text-accent mb-6">
          {topic} Physiotherapy
        </h1>
        <p className="text-lg md:text-xl text-muted-foreground mb-8">
          {introduction}
        </p>
      </motion.div>
    </div>
  </section>
);

export default PageHeader;