// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://wbvjqaqlxzfsxyqkqzfj.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndidmpxYXFseHpmc3h5cWtxemZqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzUxNjI2NTQsImV4cCI6MjA1MDczODY1NH0._j4gptng4dxKVAhdn1iDjsK6GMdjWK75r2WA_Bi28ro";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);