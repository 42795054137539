import { motion } from "framer-motion";

const services = [
  {
    title: "General Physiotherapy",
    description: "Comprehensive assessment and treatment for various physical conditions.",
    icon: "🏥",
  },
  {
    title: "Sports Injury",
    description: "Specialized care for athletes and sports-related injuries.",
    icon: "🏃",
  },
  {
    title: "Chronic Pain",
    description: "Long-term pain management and rehabilitation programs.",
    icon: "💆",
  },
  {
    title: "Post-Surgery",
    description: "Rehabilitation support following surgical procedures.",
    icon: "⚕️",
  },
];

const Services = () => {
  return (
    <section className="py-20 bg-white" id="services">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <span className="inline-block px-4 py-1 mb-4 text-sm font-medium text-primary bg-primary/10 rounded-full">
            Our Services
          </span>
          <h2 className="text-3xl md:text-4xl font-bold text-accent mb-4">
            Comprehensive Physiotherapy Solutions
          </h2>
          <p className="text-muted-foreground max-w-2xl mx-auto">
            We offer a wide range of physiotherapy services tailored to your specific needs and recovery goals.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {services.map((service, index) => (
            <motion.div
              key={service.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              viewport={{ once: true }}
              className="bg-secondary p-6 rounded-xl hover:shadow-lg transition-shadow duration-300"
            >
              <div className="text-4xl mb-4">{service.icon}</div>
              <h3 className="text-xl font-semibold mb-2 text-accent">{service.title}</h3>
              <p className="text-muted-foreground">{service.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;