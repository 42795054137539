import { supabase } from "@/integrations/supabase/client";

export const generateNewContent = async (topic: string) => {
  console.log('Generating new content for:', topic);
  
  const { data: generatedData, error: generateError } = await supabase.functions
    .invoke('generate-content', {
      body: { 
        topic: topic.replace(/-/g, ' '),
        type: 'treatment'
      }
    });

  if (generateError) {
    console.error("Error generating content:", generateError);
    throw generateError;
  }

  return generatedData;
};