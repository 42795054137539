const TreatmentApproaches = () => {
  return (
    <div>
      <h2 className="text-2xl font-bold text-accent mb-6">Our Gentle Treatment Approaches</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="p-6 bg-white rounded-lg shadow-sm">
          <h3 className="font-semibold mb-2">Manual Therapy</h3>
          <p>Soft tissue techniques and gentle joint mobilization to reduce pain and improve mobility.</p>
        </div>
        <div className="p-6 bg-white rounded-lg shadow-sm">
          <h3 className="font-semibold mb-2">Therapeutic Exercises</h3>
          <p>Carefully designed movement patterns that respect your body's limitations.</p>
        </div>
      </div>
    </div>
  );
};

export default TreatmentApproaches;