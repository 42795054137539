import { motion } from "framer-motion";
import ReactMarkdown from "react-markdown";

interface BenefitsSectionProps {
  benefits: string[];
}

const BenefitsSection = ({ benefits }: BenefitsSectionProps) => (
  <section className="py-16 bg-secondary">
    <div className="container mx-auto px-4">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-3xl font-bold text-accent mb-6">Benefits</h2>
        <ul className="space-y-4">
          {benefits.map((benefit, index) => (
            <motion.li
              key={index}
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
              className="flex items-start"
            >
              <span className="text-primary mr-2">•</span>
              <ReactMarkdown className="prose prose-lg">{benefit}</ReactMarkdown>
            </motion.li>
          ))}
        </ul>
      </div>
    </div>
  </section>
);

export default BenefitsSection;