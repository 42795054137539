const TreatmentTimeline = () => {
  return (
    <div>
      <h2 className="text-2xl font-bold text-accent mb-6">Treatment Timeline</h2>
      <ol className="space-y-4">
        <li className="flex items-start">
          <span className="font-bold mr-2">1.</span>
          <span>Initial Assessment (Week 1): Thorough evaluation and personalized treatment plan</span>
        </li>
        <li className="flex items-start">
          <span className="font-bold mr-2">2.</span>
          <span>Early Treatment (Weeks 2-3): Gentle techniques to manage pain and build confidence</span>
        </li>
        <li className="flex items-start">
          <span className="font-bold mr-2">3.</span>
          <span>Progressive Care (Weeks 4-6): Gradual increase in activity as comfort improves</span>
        </li>
      </ol>
    </div>
  );
};

export default TreatmentTimeline;