import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Contact from "@/components/Contact";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";

const chronicConditions = [
  "fibromyalgia",
  "lower-back-pain",
  "neck-pain",
  "arthritis",
  "sciatica",
];

const ChronicPain = () => {
  const pageTitle = "Chronic Pain Management Adelaide | Expert Physiotherapy";
  const pageDescription = "Specialized chronic pain management in Adelaide. Professional physiotherapy treatment for fibromyalgia, arthritis, back pain and more. Evidence-based approach for lasting relief.";
  const canonicalUrl = "https://physioadelaide.com/chronic-pain";
  const ogImage = "https://physioadelaide.com/og-image.png";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:site_name" content="Physio Adelaide" />
        <meta property="og:locale" content="en_AU" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={ogImage} />
        <meta name="geo.region" content="AU-SA" />
        <meta name="geo.placename" content="Adelaide" />
        <meta name="geo.position" content="-34.9285;138.6007" />
        <meta name="ICBM" content="-34.9285, 138.6007" />
      </Helmet>

      <main className="min-h-screen">
        <div className="container mx-auto px-4 py-4">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink href="/">Home</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>Chronic Pain</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>

        <section className="py-20 bg-secondary">
          <div className="container mx-auto px-4">
            <h1 className="text-4xl md:text-6xl font-bold text-accent mb-8">
              Chronic Pain Management
            </h1>
            <p className="text-lg md:text-xl text-muted-foreground mb-8">
              Expert physiotherapy care for chronic pain conditions. Our evidence-based approach helps you manage pain and improve quality of life.
            </p>
          </div>
        </section>

        <section className="py-20">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-accent mb-8">
              Chronic Pain Conditions We Treat
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {chronicConditions.map((condition) => (
                <Link
                  key={condition}
                  to={`/treatment/${condition}`}
                  className="p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow"
                >
                  <h3 className="text-xl font-semibold text-accent mb-2">
                    {condition.split("-").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}
                  </h3>
                  <p className="text-muted-foreground">
                    Learn more about treatment options →
                  </p>
                </Link>
              ))}
            </div>
          </div>
        </section>

        <Contact />
      </main>
    </>
  );
};

export default ChronicPain;