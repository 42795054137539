import { Mail, MapPin, Phone, Clock } from "lucide-react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-accent text-white py-12 mt-20">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
            <div className="space-y-3">
              <div className="flex items-center gap-2">
                <MapPin className="h-5 w-5" />
                <p>Suite 38 level 30, 91 King William St, Adelaide 5000</p>
              </div>
              <div className="flex items-center gap-2">
                <Phone className="h-5 w-5" />
                <a href="tel:0412982516" className="hover:underline">0412 982 516</a>
              </div>
              <div className="flex items-center gap-2">
                <Mail className="h-5 w-5" />
                <a href="mailto:hello@physioadelaide.com" className="hover:underline">
                  hello@physioadelaide.com
                </a>
              </div>
            </div>
          </div>
          
          <div>
            <h3 className="text-xl font-semibold mb-4">Trading Hours</h3>
            <div className="space-y-2">
              <div className="flex items-center gap-2">
                <Clock className="h-5 w-5" />
                <div>
                  <p>Monday - Friday: 8am - 6pm</p>
                  <p>Saturday & Sunday: 9am - 4pm</p>
                </div>
              </div>
            </div>
          </div>
          
          <div>
            <h3 className="text-xl font-semibold mb-4">Quick Links</h3>
            <div className="space-y-2">
              <Link to="/sports-injury" className="block hover:underline">Sports Injury</Link>
              <Link to="/chronic-pain" className="block hover:underline">Chronic Pain</Link>
              <Link to="/post-surgery" className="block hover:underline">Post-Surgery</Link>
              <Link to="/geriatric-care" className="block hover:underline">Geriatric Care</Link>
            </div>
          </div>
        </div>
        
        <div className="mt-8 pt-8 border-t border-white/20 text-center">
          <p>&copy; {new Date().getFullYear()} Physio Adelaide. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;