import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Button } from "./ui/button";

const Hero = () => {
  return (
    <section className="relative h-screen flex items-center justify-center overflow-hidden bg-secondary">
      <div className="container mx-auto px-4 z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-center max-w-4xl mx-auto"
        >
          <span className="inline-block px-4 py-1 mb-2 -mt-16 text-sm font-medium text-primary bg-primary/10 rounded-full animate-fade-up">
            Welcome to PhysioAdelaide
          </span>
          <h1 className="text-4xl md:text-6xl font-bold text-accent mb-6 animate-fade-up">
            Expert Physiotherapy Care in Adelaide
          </h1>
          <p className="text-lg md:text-xl text-muted-foreground mb-8 animate-fade-up">
            Specialized treatment plans tailored to your needs, helping you recover faster and live better.
          </p>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="space-y-4"
          >
            <div className="flex flex-wrap justify-center gap-4 mb-4">
              <Link to="/manual-therapy">
                <Button variant="outline" size="lg">
                  Manual Therapy
                </Button>
              </Link>
              <Link to="/gentle-treatment">
                <Button variant="outline" size="lg">
                  Gentle Treatment
                </Button>
              </Link>
              <Link to="/suburbs">
                <Button variant="outline" size="lg">
                  Find Local Services
                </Button>
              </Link>
            </div>
            <div>
              <a
                href="#contact"
                className="inline-block px-8 py-4 bg-primary text-white rounded-lg font-medium transition-transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
              >
                Book Appointment
              </a>
            </div>
          </motion.div>
        </motion.div>
      </div>
      <div 
        className="absolute inset-0 bg-[url('/lovable-uploads/54768107-fd6e-43e3-ae9d-e75735133195.png')] bg-cover bg-center opacity-30 blur-[2px]"
        style={{ filter: 'brightness(1.1)' }}
      />
      <div className="absolute inset-0 bg-secondary/60" />
    </section>
  );
};

export default Hero;