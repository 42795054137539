import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";

const faqs = [
  {
    question: "Do you need a referral to see a physio in Australia?",
    answer: "No, you don't need a referral to see a physiotherapist in Australia. However, if you wish to claim through Medicare's Chronic Disease Management plan, you'll need a GP referral."
  },
  {
    question: "Is seeing a physio worth it?",
    answer: "Yes, physiotherapy can be highly beneficial for both treating existing conditions and preventing future problems. Professional physiotherapy can help:\n\n• Reduce pain and discomfort from injuries or chronic conditions\n• Improve mobility and physical function\n• Speed up recovery from surgery or injury\n• Prevent future injuries through proper movement education\n• Enhance athletic performance and daily activities"
  },
  {
    question: "How does your treatment approach differ from other therapies/clinics?",
    answer: "Our assessment detects and eases tension throughout the body. We utilize gentle compressions on specific problem areas to encourage healing and improve blood circulation. Instead of employing aggressive stretching or forceful techniques on the muscles, we focus on soothing symptoms by positioning inflamed tissues in comfortable ways. This approach enables us to target deeper layers of tension, which are often the root causes of chronic or ongoing issues, thereby ensuring more enduring results. By emphasizing a gentle, non-invasive technique, we facilitate the body's natural healing processes and foster an environment conducive to true recovery."
  },
  {
    question: "Will I get treated at my first visit?",
    answer: "Yes, we dedicate 60 minutes to a combination of assessments to get to know your condition as well as time to treat you."
  },
  {
    question: "How long are your sessions?",
    answer: "Initial and follow-up sessions are 45 to 60 minutes. We ensure our approach remains comprehensive and holistic, giving adequate time to address your needs without rushing."
  },
  {
    question: "Can your team help me if I'm not in pain?",
    answer: "Most definitely. The scope of our work goes beyond treating pain. Prevention is always key when maintaining your physical health."
  },
  {
    question: "Are you a preferred provider with my private health insurer?",
    answer: "No, and here's the reason. Preferred providers usually offer much shorter session lengths of just 15 to 20 minutes, which leaves insufficient time for a thorough assessment and effective treatment. This limitation arises because health insurers impose caps on the fees clinics can charge for sessions to keep them gap-free. We believe in dedicating more time to our patients to gain a comprehensive understanding of their conditions, ultimately helping them feel better more quickly."
  },
  {
    question: "What experience do you have?",
    answer: "Our commitment to staying cutting-edge with the latest research and treatment methods is our utmost priority. Our therapists regularly enhance their skills through international training opportunities. With extensive experience across sports and private practice, our highly skilled team brings a wealth of expertise to every treatment.\n\nOur comprehensive approach aims to restore your body's balance by addressing multiple aspects of your health. We focus on reducing tension, alleviating pain, improving posture alignment, enhancing range of motion, and building strength. This holistic strategy promotes overall wellness and faster recovery.\n\nWe're passionate about advancing not just our own skills, but the entire physiotherapy industry. We provide our team with regular workshops and training sessions. This commitment to continued education ensures that our therapists remain at the forefront of physiotherapy practices, benefiting both our patients and the broader healthcare community."
  }
];

const FAQ = () => {
  return (
    <section className="py-20 bg-secondary" id="faq">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <span className="inline-block px-4 py-1 mb-4 text-sm font-medium text-primary bg-primary/10 rounded-full">
            FAQ
          </span>
          <h2 className="text-3xl md:text-4xl font-bold text-accent mb-4">
            Frequently Asked Questions
          </h2>
          <p className="text-muted-foreground max-w-2xl mx-auto">
            Find answers to common questions about our physiotherapy services
          </p>
        </div>
        <div className="max-w-3xl mx-auto">
          <Accordion type="single" collapsible className="w-full">
            {faqs.map((faq, index) => (
              <AccordionItem key={index} value={`item-${index}`}>
                <AccordionTrigger className="text-left">
                  {faq.question}
                </AccordionTrigger>
                <AccordionContent className="text-muted-foreground whitespace-pre-line">
                  {faq.answer}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default FAQ;