import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import Index from "./pages/Index";
import ManualTherapy from "./pages/ManualTherapy";
import TreatmentDetail from "./pages/TreatmentDetail";
import SportsInjury from "./pages/SportsInjury";
import ChronicPain from "./pages/ChronicPain";
import PostSurgery from "./pages/PostSurgery";
import GeriatricCare from "./pages/GeriatricCare";
import GentleTreatment from "./pages/GentleTreatment";
import Suburbs from "./pages/Suburbs";
import SuburbDetail from "./pages/SuburbDetail";
import DynamicPage from "./components/DynamicPage";
import { Toaster } from "@/components/ui/toaster";
import "./App.css";

function App() {
  return (
    <Router>
      <Navigation />
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/manual-therapy" element={<ManualTherapy />} />
        <Route path="/manual-therapy/:treatment" element={<TreatmentDetail />} />
        <Route path="/treatment/:treatment" element={<TreatmentDetail />} />
        <Route path="/condition/:condition" element={<DynamicPage />} />
        <Route path="/sports-injury" element={<SportsInjury />} />
        <Route path="/chronic-pain" element={<ChronicPain />} />
        <Route path="/post-surgery" element={<PostSurgery />} />
        <Route path="/geriatric-care" element={<GeriatricCare />} />
        <Route path="/gentle-treatment" element={<GentleTreatment />} />
        <Route path="/suburbs" element={<Suburbs />} />
        <Route path="/suburbs/:suburb" element={<SuburbDetail />} />
      </Routes>
      <Footer />
      <Toaster />
    </Router>
  );
}

export default App;