import { useEffect, useState } from "react";
import { Checkbox } from "@/components/ui/checkbox";
import { supabase } from "@/integrations/supabase/client";

interface Goal {
  id: string;
  category: string;
  description: string;
}

interface GoalsSectionProps {
  selectedGoals: string[];
  onGoalsChange: (goals: string[]) => void;
}

const GoalsSection = ({ selectedGoals, onGoalsChange }: GoalsSectionProps) => {
  const [goals, setGoals] = useState<Goal[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGoals = async () => {
      try {
        const { data, error } = await supabase
          .from("physio_goals")
          .select("*")
          .order("category");

        if (error) throw error;
        setGoals(data || []);
      } catch (error) {
        console.error("Error fetching goals:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGoals();
  }, []);

  const handleGoalChange = (goalId: string, checked: boolean) => {
    if (checked) {
      onGoalsChange([...selectedGoals, goalId]);
    } else {
      onGoalsChange(selectedGoals.filter((id) => id !== goalId));
    }
  };

  if (loading) {
    return <div>Loading goals...</div>;
  }

  const groupedGoals = goals.reduce((acc, goal) => {
    if (!acc[goal.category]) {
      acc[goal.category] = [];
    }
    acc[goal.category].push(goal);
    return acc;
  }, {} as Record<string, Goal[]>);

  return (
    <div className="space-y-6">
      <div className="text-sm font-medium text-accent mb-2">
        What are your treatment goals?
      </div>
      {Object.entries(groupedGoals).map(([category, categoryGoals]) => (
        <div key={category} className="space-y-3">
          <h4 className="text-sm font-semibold text-muted-foreground">
            {category}
          </h4>
          <div className="space-y-2">
            {categoryGoals.map((goal) => (
              <div key={goal.id} className="flex items-center space-x-2">
                <Checkbox
                  id={goal.id}
                  checked={selectedGoals.includes(goal.id)}
                  onCheckedChange={(checked) =>
                    handleGoalChange(goal.id, checked as boolean)
                  }
                />
                <label
                  htmlFor={goal.id}
                  className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  {goal.description}
                </label>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default GoalsSection;