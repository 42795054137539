import { motion } from "framer-motion";
import { Medal, Clock, Users, ThumbsUp } from "lucide-react";

const features = [
  {
    icon: <Medal className="w-8 h-8 text-primary" />,
    title: "Expert Physiotherapists",
    description: "Our team consists of highly qualified and experienced physiotherapists dedicated to your recovery."
  },
  {
    icon: <Clock className="w-8 h-8 text-primary" />,
    title: "Flexible Scheduling",
    description: "We offer convenient appointment times to fit your busy lifestyle, including early morning and evening slots."
  },
  {
    icon: <Users className="w-8 h-8 text-primary" />,
    title: "Personalized Care",
    description: "Every treatment plan is tailored to your specific needs and recovery goals."
  },
  {
    icon: <ThumbsUp className="w-8 h-8 text-primary" />,
    title: "Proven Results",
    description: "Our evidence-based approach has helped thousands of patients recover and return to their active lifestyle."
  }
];

const WhyChooseUs = () => {
  return (
    <section className="py-20 bg-white" id="why-choose-us">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <span className="inline-block px-4 py-1 mb-4 text-sm font-medium text-primary bg-primary/10 rounded-full">
            Why Choose Us
          </span>
          <h2 className="text-3xl md:text-4xl font-bold text-accent mb-4">
            Adelaide's Trusted Physiotherapy Clinic
          </h2>
          <p className="text-muted-foreground max-w-2xl mx-auto">
            Experience the difference with our professional and caring approach to physiotherapy.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              viewport={{ once: true }}
              className="text-center p-6 rounded-xl hover:bg-secondary transition-colors duration-300"
            >
              <div className="mb-4 flex justify-center">{feature.icon}</div>
              <h3 className="text-xl font-semibold mb-2 text-accent">{feature.title}</h3>
              <p className="text-muted-foreground">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;