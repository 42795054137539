import { supabase } from "@/integrations/supabase/client";

export const fetchExistingContent = async (condition: string) => {
  console.log('Fetching existing content for:', condition);
  
  const { data: existingContent, error: fetchError } = await supabase
    .from("condition_content")
    .select()
    .eq("condition_slug", condition)
    .maybeSingle();

  if (fetchError) {
    console.error("Error fetching existing content:", fetchError);
    throw fetchError;
  }

  // Check if content exists and is not expired
  if (existingContent) {
    const expiryDate = new Date(existingContent.cache_expires_at);
    if (expiryDate > new Date()) {
      console.log('Found valid cached content');
      return existingContent;
    }
    console.log('Content exists but is expired');
  }

  return null;
};