import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

const ProfileCard = () => {
  return (
    <>
      <div className="flex flex-col items-center mb-8">
        <Avatar className="h-[172px] w-[144px] mb-4">
          <AvatarImage src="/lovable-uploads/e65d8b7c-4990-4c50-af90-6802960eac44.png" alt="Philip Chau" />
          <AvatarFallback>PC</AvatarFallback>
        </Avatar>
      </div>
      
      <Card className="mb-12 bg-gradient-to-br from-[#F1F0FB]/25 to-[#E5DEFF]/25 border-[#9b87f5]/25">
        <CardHeader>
          <CardTitle className="text-2xl text-[#1A1F2C]">Featured Practitioner</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-[#7E69AB] mb-4">Philip Chau</h3>
              <div className="prose text-[#1A1F2C] space-y-4">
                <p>
                  Philip Chau is a dedicated physiotherapist who provides a holistic approach to managing chronic pain through gentle and comprehensive treatment.
                </p>
                <p>
                  His extensive experience in hospital, disability, and community work, combined with his ongoing training in Fascial Counterstrain, allows him to offer innovative and compassionate care to his patients.
                </p>
                <p>
                  At the core of Philip's practice is a commitment to finding the root cause of issues through thorough assessment.
                </p>
                <p>
                  Philip's treatment methodology is designed to be gentle and painless, making it suitable for even the most sensitive cases. He utilizes a variety of techniques, including manual therapy, to relax fascia AKA the connective tissue and reduce inflammation and improve the management of pain. This comprehensive approach often results in lasting relief for chronic conditions.
                </p>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default ProfileCard;