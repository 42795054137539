import { Helmet } from "react-helmet";
import Hero from "../components/Hero";
import Services from "../components/Services";
import FAQ from "../components/FAQ";
import WhyChooseUs from "../components/WhyChooseUs";
import Contact from "../components/Contact";

const Index = () => {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "PhysicalTherapist",
    "name": "PhysioAdelaide",
    "description": "Professional physiotherapy services in Adelaide. Specialized treatment plans for sports injuries, chronic pain, and rehabilitation.",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Adelaide",
      "addressRegion": "SA",
      "addressCountry": "AU"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": "-34.9285",
      "longitude": "138.6007"
    },
    "priceRange": "$$",
    "telephone": "+61412982516",
    "openingHours": "Mo-Fr 08:00-18:00",
    "areaServed": "Adelaide Metropolitan Area"
  };

  return (
    <>
      <Helmet>
        <html lang="en-AU" />
        <title>PhysioAdelaide - Expert Physiotherapy Care in Adelaide</title>
        <meta
          name="description"
          content="Professional physiotherapy services in Adelaide. Specialized treatment plans for sports injuries, chronic pain, and rehabilitation. Book your appointment today."
        />
        <meta name="keywords" content="physiotherapy adelaide, physio adelaide, sports physiotherapy, chronic pain management, rehabilitation" />
        <link rel="canonical" href="https://physioadelaide.com" />
        
        {/* Open Graph Tags */}
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="PhysioAdelaide" />
        <meta property="og:title" content="PhysioAdelaide - Expert Physiotherapy Care in Adelaide" />
        <meta property="og:description" content="Professional physiotherapy services in Adelaide. Specialized treatment plans for sports injuries, chronic pain, and rehabilitation." />
        <meta property="og:url" content="https://physioadelaide.com" />
        <meta property="og:image" content="https://physioadelaide.com/og-image.png" />
        <meta property="og:locale" content="en_AU" />
        
        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="PhysioAdelaide - Expert Physiotherapy Care" />
        <meta name="twitter:description" content="Professional physiotherapy services in Adelaide. Book your appointment today." />
        <meta name="twitter:image" content="https://physioadelaide.com/og-image.png" />
        
        {/* Additional SEO Meta Tags */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="PhysioAdelaide" />
        <meta name="geo.region" content="SA" />
        <meta name="geo.placename" content="Adelaide" />
        <meta name="geo.position" content="-34.9285;138.6007" />
        <meta name="ICBM" content="-34.9285, 138.6007" />
        
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>
      <main className="min-h-screen">
        <Hero />
        <Services />
        <WhyChooseUs />
        <FAQ />
        <Contact />
      </main>
    </>
  );
};

export default Index;