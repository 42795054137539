import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { MapPin } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { Helmet } from "react-helmet";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";

const Suburbs = () => {
  const { data: suburbs, isLoading } = useQuery({
    queryKey: ['suburbs'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('suburbs')
        .select('*')
        .order('name');
      
      if (error) throw error;
      return data;
    }
  });

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Find a Physiotherapist in Your Suburb | Physio Adelaide</title>
        <meta 
          name="description" 
          content="Discover local physiotherapy services across Adelaide suburbs. Expert care close to home for all your physiotherapy needs."
        />
      </Helmet>

      <div className="container mx-auto px-4 py-4">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link to="/">Home</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Suburbs</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      
      <section className="py-20 bg-secondary">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl md:text-6xl font-bold text-accent text-center mb-6">
            Find a Physiotherapist Near You
          </h1>
          <p className="text-lg md:text-xl text-muted-foreground text-center mb-12">
            Select your suburb to discover local physiotherapy services and information
          </p>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {suburbs?.map((suburb) => (
              <Link 
                key={suburb.id} 
                to={`/suburbs/${suburb.name.toLowerCase().replace(/ /g, '_')}`}
              >
                <Card className="hover:shadow-lg transition-shadow">
                  <CardHeader>
                    <CardTitle className="flex items-center gap-2">
                      <MapPin className="h-5 w-5 text-primary" />
                      {suburb.name}
                    </CardTitle>
                  </CardHeader>
                </Card>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Suburbs;