import ReactMarkdown from "react-markdown";

interface ProcessSectionProps {
  process: string;
}

const ProcessSection = ({ process }: ProcessSectionProps) => (
  <section className="py-16">
    <div className="container mx-auto px-4">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-3xl font-bold text-accent mb-6">
          Treatment Process
        </h2>
        <ReactMarkdown className="prose prose-lg max-w-none space-y-6">
          {process}
        </ReactMarkdown>
      </div>
    </div>
  </section>
);

export default ProcessSection;