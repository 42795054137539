import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useToast } from "@/hooks/use-toast";
import { fetchExistingContent } from "@/utils/content/fetchContent";
import { generateNewContent } from "@/utils/content/generateContent";
import { saveContent } from "@/utils/content/saveContent";

interface ContentLoaderProps {
  condition: string;
  onContentLoaded: (content: any) => void;
  onLoadingChange: (loading: boolean) => void;
}

const ContentLoader = ({ condition, onContentLoaded, onLoadingChange }: ContentLoaderProps) => {
  const { toast } = useToast();

  const { data: content, isLoading } = useQuery({
    queryKey: ['condition', condition],
    queryFn: async () => {
      if (!condition) return null;
      
      try {
        console.log("Fetching content for condition:", condition);
        
        // Try to fetch existing content
        const existingContent = await fetchExistingContent(condition);
        
        if (existingContent) {
          console.log('Found cached content for:', condition);
          return existingContent;
        }

        // Generate new content if none exists
        console.log('No cached content found, generating new content for:', condition);
        const generatedData = await generateNewContent(condition);
        
        // Save the generated content
        console.log('Saving newly generated content for:', condition);
        const savedContent = await saveContent(
          condition,
          generatedData,
          false
        );

        return savedContent;
        
      } catch (error) {
        console.error("Error in content flow:", error);
        toast({
          title: "Error",
          description: "Failed to load content. Please try again later.",
          variant: "destructive",
        });
        throw error;
      }
    },
    staleTime: 1000 * 60 * 5, // Consider data fresh for 5 minutes
    gcTime: 1000 * 60 * 30, // Keep in cache for 30 minutes
  });

  useEffect(() => {
    if (content) {
      console.log('Content loaded successfully:', condition);
      onContentLoaded(content);
    }
    onLoadingChange(isLoading);
  }, [content, isLoading, onContentLoaded, onLoadingChange]);

  return null;
};

export default ContentLoader;