import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import Contact from "@/components/Contact";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Link } from "react-router-dom";

const SuburbDetail = () => {
  const { suburb } = useParams();
  const formattedSuburb = suburb?.split('_').map(word => 
    word.charAt(0).toUpperCase() + word.slice(1)
  ).join(' ');

  const { data: suburbData, isLoading } = useQuery({
    queryKey: ['suburb', suburb],
    queryFn: async () => {
      const { data: cachedContent } = await supabase
        .from('suburb_content')
        .select('*')
        .eq('suburb_slug', suburb)
        .maybeSingle();

      if (cachedContent && new Date(cachedContent.expires_at) > new Date()) {
        return {
          content: cachedContent.content,
          name: formattedSuburb
        };
      }

      // If no cache or expired, get suburb data and generate new content
      const { data: suburbInfo } = await supabase
        .from('suburbs')
        .select('*')
        .ilike('name', formattedSuburb || '')
        .maybeSingle();

      const content = `Looking for expert physiotherapy care in ${formattedSuburb}? Our experienced physiotherapists provide comprehensive treatment for a wide range of conditions. We understand the unique needs of ${formattedSuburb} residents and offer personalized care plans to help you achieve optimal physical health and wellness. Our ${formattedSuburb} physiotherapy services include sports injury rehabilitation, chronic pain management, post-surgery recovery, and general physical therapy. We use evidence-based techniques and state-of-the-art equipment to ensure the best possible outcomes for our patients.`;

      // Cache the content
      const { data: newCache } = await supabase
        .from('suburb_content')
        .insert({
          suburb_slug: suburb,
          content: content,
        })
        .select()
        .single();

      return {
        content,
        name: formattedSuburb
      };
    }
  });

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`Physiotherapy in ${formattedSuburb} | Physio Adelaide`}</title>
        <meta
          name="description"
          content={`Expert physiotherapy services in ${formattedSuburb}, Adelaide. Local treatment for sports injuries, chronic pain, and rehabilitation.`}
        />
      </Helmet>

      <div className="container mx-auto px-4 py-4">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link to="/">Home</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link to="/suburbs">Suburbs</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{formattedSuburb}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>

      <section className="py-20 bg-secondary">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl md:text-6xl font-bold text-accent text-center mb-6">
            Physiotherapy in {formattedSuburb}
          </h1>
          
          <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-8 mt-8">
            <p className="text-lg text-muted-foreground mb-6">
              {suburbData?.content}
            </p>
          </div>
        </div>
      </section>

      <Contact />
    </>
  );
};

export default SuburbDetail;