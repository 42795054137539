import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Contact from "@/components/Contact";
import ContentLoader from "@/components/dynamic-page/ContentLoader";
import PageHeader from "@/components/dynamic-page/PageHeader";
import ProcessSection from "@/components/dynamic-page/ProcessSection";
import BenefitsSection from "@/components/dynamic-page/BenefitsSection";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";

const TreatmentDetail = () => {
  const { treatment } = useParams();
  const location = useLocation();
  const [content, setContent] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const formattedTitle = treatment
    ? treatment.split("-").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
    : "";

  const isManualTherapyRoute = location.pathname.startsWith('/manual-therapy/');

  return (
    <>
      <Helmet>
        <title>{content?.meta_title || `${formattedTitle} Treatment Adelaide`}</title>
        <meta
          name="description"
          content={content?.meta_description || `Professional ${formattedTitle.toLowerCase()} treatment in Adelaide. Expert physiotherapy care tailored to your needs.`}
        />
      </Helmet>

      <main className="min-h-screen">
        <div className="container mx-auto px-4 py-4">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink href="/">Home</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                {isManualTherapyRoute ? (
                  <BreadcrumbLink href="/manual-therapy">Manual Therapy</BreadcrumbLink>
                ) : (
                  <BreadcrumbLink href="/treatment">Treatments</BreadcrumbLink>
                )}
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>{formattedTitle}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>

        {loading ? (
          <div className="container mx-auto px-4 py-20">
            <div className="max-w-4xl mx-auto">
              <div className="animate-pulse space-y-4">
                <div className="h-8 bg-gray-200 rounded w-3/4"></div>
                <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                <div className="h-4 bg-gray-200 rounded w-5/6"></div>
              </div>
            </div>
          </div>
        ) : !content ? (
          <div className="min-h-screen flex flex-col items-center justify-center p-4">
            <h1 className="text-2xl font-bold text-accent mb-4">Content Not Found</h1>
            <p className="text-muted-foreground text-center">
              We couldn't find any information about {formattedTitle}. Please try another condition or contact us for more information.
            </p>
          </div>
        ) : (
          <>
            <PageHeader
              topic={formattedTitle}
              introduction={content?.content?.introduction || ""}
            />
            <BenefitsSection benefits={content?.content?.benefits || []} />
            <ProcessSection process={content?.content?.process || ""} />
            <Contact />
          </>
        )}
      </main>

      <ContentLoader
        condition={treatment || ""}
        onContentLoaded={setContent}
        onLoadingChange={setLoading}
      />
    </>
  );
};

export default TreatmentDetail;