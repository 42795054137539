import { supabase } from "@/integrations/supabase/client";

export const saveContent = async (condition: string, content: any, isUpdate: boolean) => {
  console.log('Saving content for:', condition, 'isUpdate:', isUpdate);
  
  const now = new Date();
  const expiryDate = new Date(now.getTime() + (180 * 24 * 60 * 60 * 1000)); // 180 days

  if (isUpdate) {
    const { data: updatedContent, error: updateError } = await supabase
      .from('condition_content')
      .update({
        meta_title: content.meta_title,
        meta_description: content.meta_description,
        content: content.content,
        last_updated: now.toISOString(),
        cache_expires_at: expiryDate.toISOString()
      })
      .eq('condition_slug', condition)
      .select()
      .single();

    if (updateError) {
      console.error("Error updating content:", updateError);
      throw updateError;
    }

    return updatedContent;
  } else {
    const { data: newContent, error: insertError } = await supabase
      .from('condition_content')
      .insert([{
        condition_slug: condition,
        meta_title: content.meta_title,
        meta_description: content.meta_description,
        content: content.content,
        cache_expires_at: expiryDate.toISOString()
      }])
      .select()
      .single();

    if (insertError) {
      console.error("Error saving content:", insertError);
      throw insertError;
    }

    return newContent;
  }
};