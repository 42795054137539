import { useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Skeleton } from "@/components/ui/skeleton";
import PageHeader from "./dynamic-page/PageHeader";
import ProcessSection from "./dynamic-page/ProcessSection";
import BenefitsSection from "./dynamic-page/BenefitsSection";
import Contact from "./Contact";
import ContentLoader from "./dynamic-page/ContentLoader";

interface ConditionContent {
  id: string;
  condition_slug: string;
  meta_title: string;
  meta_description: string;
  content: {
    introduction: string;
    process: string;
    benefits: string[];
    images?: string[];
  };
  last_updated?: string;
}

const LoadingSkeleton = () => (
  <div className="animate-in fade-in-50 duration-500">
    <div className="py-20 bg-secondary">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto text-center space-y-4">
          <Skeleton className="h-12 w-3/4 mx-auto" />
          <Skeleton className="h-24 w-full mx-auto" />
        </div>
      </div>
    </div>
    <div className="py-16">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto space-y-6">
          <Skeleton className="h-8 w-48" />
          <div className="space-y-4">
            <Skeleton className="h-4 w-full" />
            <Skeleton className="h-4 w-5/6" />
            <Skeleton className="h-4 w-4/6" />
          </div>
        </div>
      </div>
    </div>
    <div className="py-16 bg-secondary">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto space-y-6">
          <Skeleton className="h-8 w-48" />
          <div className="space-y-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="flex items-start space-x-2">
                <Skeleton className="h-4 w-4" />
                <Skeleton className="h-4 w-5/6" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

const ErrorState = ({ condition }: { condition: string }) => (
  <div className="min-h-screen flex flex-col items-center justify-center p-4">
    <h1 className="text-2xl font-bold text-accent mb-4">Content Not Found</h1>
    <p className="text-muted-foreground text-center">
      We couldn't find any information about {condition}. Please try another condition or contact us for more information.
    </p>
  </div>
);

const DynamicPage = () => {
  const { condition } = useParams();
  const [pageContent, setPageContent] = useState<ConditionContent | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const formattedCondition = condition
    ?.split("-")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ") || "";

  console.log('Current condition:', condition);
  console.log('Loading state:', isLoading);
  console.log('Page content:', pageContent);

  return (
    <>
      <Helmet>
        <title>{pageContent?.meta_title || `${formattedCondition} Treatment | Physio Adelaide`}</title>
        <meta
          name="description"
          content={pageContent?.meta_description || `Expert physiotherapy treatment for ${formattedCondition} in Adelaide. Evidence-based approach for optimal recovery.`}
        />
      </Helmet>
      
      <ContentLoader 
        condition={condition || ""} 
        onContentLoaded={setPageContent}
        onLoadingChange={setLoading => {
          console.log('Loading state changed:', setLoading);
          setIsLoading(setLoading);
        }}
      />

      {isLoading ? (
        <LoadingSkeleton />
      ) : !pageContent ? (
        <ErrorState condition={formattedCondition} />
      ) : (
        <>
          <PageHeader 
            topic={formattedCondition} 
            introduction={pageContent?.content?.introduction || ""}
          />
          <ProcessSection 
            process={pageContent?.content?.process || ""}
          />
          <BenefitsSection 
            benefits={pageContent?.content?.benefits || []}
          />
          <Contact />
        </>
      )}
    </>
  );
};

export default DynamicPage;